var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"scrollable":"","max-width":"600px"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"dialog-header",attrs:{"dark":""}},[(_vm.action == 'Add')?_c('span',[_vm._v(" Add New Employee Type")]):_vm._e(),(_vm.action == 'Update')?_c('span',[_vm._v("Employment Details")]):_vm._e(),_c('v-spacer'),_c('v-btn',{attrs:{"icon":"","dark":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-col'),_c('v-card-text',{staticClass:"my-4",staticStyle:{"max-height":"700px"}},[_c('v-form',{ref:"Formref",on:{"submit":function($event){$event.preventDefault();}}},[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","readonly":"","required":"","label":"Employee Name","color":"#93CB5B"},model:{value:(_vm.employeeDetModel.name),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "name", $$v)},expression:"employeeDetModel.name"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-text-field',{attrs:{"dense":"","required":"","label":"Employee ID","color":"#93CB5B"},model:{value:(_vm.employeeDetModel.empID),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "empID", $$v)},expression:"employeeDetModel.empID"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-menu',{ref:"dateHiredMenu",attrs:{"close-on-content-click":false,"return-value":_vm.employeeDetModel.date_hired,"transition":"scale-transition","offset-y":"","min-width":"auto"},on:{"update:returnValue":function($event){return _vm.$set(_vm.employeeDetModel, "date_hired", $event)},"update:return-value":function($event){return _vm.$set(_vm.employeeDetModel, "date_hired", $event)}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Date hired","dense":"","rules":[_vm.formRules.required],"chips":"","readonly":"","small-chips":"","color":"#6DB249"},model:{value:(_vm.employeeDetModel.date_hired),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "date_hired", $$v)},expression:"employeeDetModel.date_hired"}},'v-text-field',attrs,false),on))]}}])},[_c('v-date-picker',{attrs:{"no-title":"","color":"#6DB249","scrollable":""},model:{value:(_vm.employeeDetModel.date_hired),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "date_hired", $$v)},expression:"employeeDetModel.date_hired"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"#6DB249"},on:{"click":function($event){return _vm.$refs.dateHiredMenu.save(_vm.employeeDetModel.date_hired)}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"rules":[_vm.formRules.required],"dense":"","label":"Employment Status","item-text":"description","item-value":"id","color":"#93CB5B","items":_vm.empStatusList},model:{value:(_vm.employeeDetModel.empStatusID),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "empStatusID", $$v)},expression:"employeeDetModel.empStatusID"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"label":"Position","rules":[_vm.formRules.required],"dense":"","item-text":"description","item-value":"id","color":"#93CB5B","items":_vm.positionList},model:{value:(_vm.employeeDetModel.positionID),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "positionID", $$v)},expression:"employeeDetModel.positionID"}})],1),(
                  _vm.employeeDetModel.positionID == 2 ||
                    _vm.employeeDetModel.positionID == 3 ||
                    _vm.employeeDetModel.positionID == 20 ||
                    _vm.employeeDetModel.positionID == 21 ||
                    _vm.employeeDetModel.positionID == 22 ||
                    _vm.employeeDetModel.positionID == 23 ||
                    _vm.employeeDetModel.positionID == 24 ||
                    _vm.employeeDetModel.positionID == 25 ||
                    _vm.employeeDetModel.positionID == 26 ||
                    _vm.employeeDetModel.positionID == 27 ||
                    _vm.employeeDetModel.positionID == 28 ||
                    _vm.employeeDetModel.positionID == 29 ||
                    _vm.employeeDetModel.positionID == 30 ||
                    _vm.employeeDetModel.positionID == 31 ||
                    _vm.employeeDetModel.positionID == 32 ||
                    _vm.employeeDetModel.positionID == 33 ||
                    _vm.employeeDetModel.positionID == 34 ||
                    _vm.employeeDetModel.positionID == 35
                )?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"label":"Institute","rules":_vm.employeeDetModel.positionID == 3 ||
                    _vm.employeeDetModel.positionID == 20 ||
                    _vm.employeeDetModel.positionID == 21 ||
                    _vm.employeeDetModel.positionID == 22 ||
                    _vm.employeeDetModel.positionID == 23 ||
                    _vm.employeeDetModel.positionID == 24 ||
                    _vm.employeeDetModel.positionID == 25 ||
                    _vm.employeeDetModel.positionID == 26 ||
                    _vm.employeeDetModel.positionID == 27 ||
                    _vm.employeeDetModel.positionID == 28 ||
                    _vm.employeeDetModel.positionID == 29 ||
                    _vm.employeeDetModel.positionID == 30 ||
                    _vm.employeeDetModel.positionID == 31 ||
                    _vm.employeeDetModel.positionID == 32 ||
                    _vm.employeeDetModel.positionID == 33 ||
                    _vm.employeeDetModel.positionID == 34 ||
                    _vm.employeeDetModel.positionID == 35
                      ? [_vm.formRules.required]
                      : [],"dense":"","item-text":"name","item-value":"id","color":"#93CB5B","items":_vm.instituteList},model:{value:(_vm.employeeDetModel.instituteID),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "instituteID", $$v)},expression:"employeeDetModel.instituteID"}})],1):_vm._e(),_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{staticClass:"rounded-lg",attrs:{"label":"Office","rules":[_vm.formRules.required],"dense":"","item-text":"name","item-value":"id","color":"#93CB5B","items":_vm.officeList},model:{value:(_vm.employeeDetModel.officeID),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "officeID", $$v)},expression:"employeeDetModel.officeID"}})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('v-select',{attrs:{"items":_vm.statusList,"label":"Status","color":"#93CB5B","dense":""},model:{value:(_vm.employeeDetModel.isActive),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "isActive", $$v)},expression:"employeeDetModel.isActive"}})],1),(_vm.employeeDetModel.isActive == 'Inactive')?_c('v-col',{attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.inActiveStatusList,"label":"Inactive type","item-text":"type","item-value":"id","rules":_vm.employeeDetModel.isActive == 'Inactive'
                      ? [_vm.formRules.required]
                      : [],"color":"#93CB5B","dense":""},model:{value:(_vm.employeeDetModel.inactive_type),callback:function ($$v) {_vm.$set(_vm.employeeDetModel, "inactive_type", $$v)},expression:"employeeDetModel.inactive_type"}})],1):_vm._e()],1)],1)],1)],1),_c('v-divider'),(_vm.action == 'Add')?_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){_vm.closeDialog();
            _vm.dialog = false;}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")]),_vm._v(" Cancel ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"#519043"},on:{"click":function($event){return _vm.addUserDetail()}}},[_c('v-icon',[_vm._v("mdi-check-circle")]),_vm._v(" Save Details ")],1)],1):_vm._e(),(_vm.action == 'Update')?_c('v-card-actions',{staticClass:"pa-5"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red","outlined":""},on:{"click":function($event){return _vm.close()}}},[_c('v-icon',[_vm._v("mdi-close-circle-outline")]),_vm._v(" Cancel ")],1),_c('v-btn',{staticClass:"white--text",attrs:{"color":"#519043"},on:{"click":function($event){return _vm.updateUserDetail()}}},[_c('v-icon',[_vm._v("mdi-check-circle")]),_vm._v(" Update Details ")],1)],1):_vm._e()],1)],1),_c('fade-away-message-component',{attrs:{"displayType":"variation2","message":_vm.fadeAwayMessage.message,"header":_vm.fadeAwayMessage.header,"top":_vm.fadeAwayMessage.top,"type":_vm.fadeAwayMessage.type},model:{value:(_vm.fadeAwayMessage.show),callback:function ($$v) {_vm.$set(_vm.fadeAwayMessage, "show", $$v)},expression:"fadeAwayMessage.show"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }